import type {StudentsByFunnelStage} from '../Campaigns/types';
import type {StudentsByDomesticFunnelStage} from './CoachStats/types';

export function sumMetrics(
	metrics?: StudentsByFunnelStage | StudentsByDomesticFunnelStage,
) {
	return Object.entries(metrics ?? {}).reduce((acc, [_key, value]) => {
		return acc + (value ?? 0);
	}, 0);
}
