import {Heading, Select, Stack} from '@halp/ui';
import {StackedBarChartResponsive} from '../StackedBarChart';
import {useGroupedMetricsData} from '../use-grouped-metrics-data';
import {snakeToCamel} from '@halp/util/string';
import type {CoachingPipeline} from '@halp/util/constants';
import {COACHING_PIPELINES} from '@halp/util/constants';
import {useMemo} from 'react';
import {useI18n} from '@halp/foundation/i18n';

interface Props {
	verificationAgentMetrics: readonly {
		id: string;
		name: string;
		studentsByCoachingPipeline: {
			verificationMessaging: number;
			verificationCalling: number;
			verificationMessagingOnshore: number;
			verificationCallingOnshore: number;
		};
	}[];
	selectedVerificationAgents: string[];
	setSelectedVerificationAgents: (selected: string[]) => void;
	verificationAgents: readonly {
		id: string;
		name: string;
		pipeline: Omit<CoachingPipeline, 'COACHING'> | null;
	}[];
}

export function StudentsByPipeline({
	verificationAgentMetrics,
	selectedVerificationAgents,
	setSelectedVerificationAgents,
	verificationAgents,
}: Props) {
	const {t} = useI18n();
	const verificationMetricsSeries = useMemo(() => {
		const series =
			selectedVerificationAgents.length === 0
				? verificationAgents.map((coach) => coach.id)
				: selectedVerificationAgents;
		return series.map((id) => {
			const coach = verificationAgents.find((coach) => coach.id === id);
			return coach?.name ?? 'Loading ...';
		});
	}, [verificationAgents, selectedVerificationAgents]);

	const studentsByPipeline = useGroupedMetricsData(
		verificationAgentMetrics,
		COACHING_PIPELINES.filter((pipeline) => pipeline !== 'COACHING').map(
			(pipeline) => ({
				label: `${t(`metrics.pipelines.${pipeline.toLowerCase()}`)}`,
				key: `studentsByCoachingPipeline.${snakeToCamel(pipeline)}`,
			}),
		),
	);

	return (
		<>
			<Stack spacing="lg" justifyContent="space-between">
				<Heading type="h3" spacing="lg">
					Verification Stats
				</Heading>
				<Select
					isMulti
					value={selectedVerificationAgents.map((id) => {
						const coach = verificationAgents.find((coach) => coach.id === id);
						return {label: coach?.name ?? 'Loading ...', value: id};
					})}
					options={verificationAgents.map((coach) => {
						return {label: coach.name ?? '', value: coach.id};
					})}
					onChange={(selected) =>
						setSelectedVerificationAgents(selected.map((s) => s.value))
					}
				/>
			</Stack>
			<Stack direction="column" alignItems="flex-start">
				<Heading type="h4" spacing="sm">
					Students by Pipeline
				</Heading>
				<StackedBarChartResponsive
					height={300}
					palette="halp"
					data={studentsByPipeline}
					series={verificationMetricsSeries}
				/>
			</Stack>
		</>
	);
}
