import {useMemo} from 'react';
import {useI18n} from '@halp/foundation/i18n';
import {
	type DomesticFunnelStage,
	FUNNEL_STAGES,
	type FunnelStage,
} from '@halp/util/constants';
import {snakeToCamel} from '@halp/util/string';

export function useFunnelStageTableColumns(
	dataKey: string,
	columns: (FunnelStage | DomesticFunnelStage)[] = FUNNEL_STAGES,
) {
	const {t} = useI18n();
	return useMemo(
		() =>
			columns.map((column) => ({
				key: `${dataKey}.${snakeToCamel(column)}`,
				label: `#${t(`metrics.funnel_stages.${column.toLowerCase()}`)}`,
				sortable: false,
			})),
		[columns, dataKey, t],
	);
}
