import {accessObject} from '@halp/util/access-object';
import {useMemo} from 'react';

export function useGroupedMetricsData<Data>(
	data: readonly ({name: string} & Data)[] | undefined,
	indices: {key: string; label: string}[],
) {
	return useMemo(() => {
		if (!data) return [];

		return indices.map((index) => {
			return {
				label: index.label,
				...data.reduce((acc, item) => {
					return {
						...acc,
						[item['name']]: accessObject(item, index.key) ?? 0,
					};
				}, {}),
			};
		});
	}, [data, indices]);
}
