import {
	COACHING_FUNNEL_STAGES,
	DOMESTIC_COACHING_FUNNEL_STAGES,
} from '@halp/util';
import {useFunnelStageTableColumns} from '../StudentsByFunnelStage';
import {DataTable} from '@halp/foundation/DataTable';
import {Paragraph} from '@halp/ui/Typography';
import {sumMetrics} from '../sum-metrics';
import type {
	StudentsByDomesticFunnelStage,
	StudentsByFunnelStage,
} from './types';

interface Props {
	coachingType: 'domestic' | 'international';
	coachMetrics: readonly {
		id: string;
		name: string;
		studentsByFunnelStage: StudentsByFunnelStage;
		studentsByDomesticFunnelStage: StudentsByDomesticFunnelStage;
	}[];
}

const DEFAULT_CAPACITY = 250;

function Capacity(row: {studentsByFunnelStage: StudentsByFunnelStage}) {
	const total = sumMetrics(row.studentsByFunnelStage);
	return (
		<Paragraph bold>
			{total} ({((total / DEFAULT_CAPACITY) * 100).toFixed(0)}%)
		</Paragraph>
	);
}

function DomesticCapacity(row: {
	studentsByDomesticFunnelStage: StudentsByDomesticFunnelStage;
}) {
	const total = sumMetrics(row.studentsByDomesticFunnelStage);
	return (
		<Paragraph bold>
			{total} ({((total / DEFAULT_CAPACITY) * 100).toFixed(0)}%)
		</Paragraph>
	);
}

export function CoachMetricsTable({coachingType, coachMetrics}: Props) {
	const seriesSuffix =
		coachingType === 'domestic' ? 'DomesticFunnelStage' : 'FunnelStage';
	const funnelStageColumns = useFunnelStageTableColumns(
		`studentsBy${seriesSuffix}`,
		coachingType === 'domestic'
			? DOMESTIC_COACHING_FUNNEL_STAGES
			: COACHING_FUNNEL_STAGES,
	);

	return (
		<DataTable
			data={coachMetrics ?? []}
			summaryRow
			columns={[
				{
					key: 'name',
					label: 'Name',
					sortable: true,
				},
				...funnelStageColumns,
				{
					key: 'capacity',
					label: `Capacity (${DEFAULT_CAPACITY})`,
					sortable: false,
					customCellRender:
						coachingType === 'domestic' ? DomesticCapacity : Capacity,
				},
			]}
		/>
	);
}
