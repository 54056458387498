import {DatePicker, Grid, Heading, Select, Stack} from '@halp/ui';
import {StackedBarChartResponsive} from '../StackedBarChart';
import {CoachMetricsTable} from './CoachMetricsTable';
import type {CoachingPipeline} from '@halp/util/constants';
import {
	COACHING_FUNNEL_STAGES,
	DOMESTIC_COACHING_FUNNEL_STAGES,
} from '@halp/util/constants';
import {useGroupedMetricsData} from '../use-grouped-metrics-data';
import {snakeToCamel} from '@halp/util/string';
import {useI18n} from '@halp/foundation/i18n';
import {useMemo} from 'react';
import type {
	StudentsByDomesticFunnelStage,
	StudentsByFunnelStage,
} from './types';

interface Props {
	coachingType: 'domestic' | 'international';
	coachMetrics: readonly {
		id: string;
		name: string;
		studentsByFunnelStage: StudentsByFunnelStage;
		studentsByDomesticFunnelStage: StudentsByDomesticFunnelStage;
	}[];
	coaches: readonly {
		id: string;
		name: string;
		pipeline: CoachingPipeline | null;
	}[];
	signUpAfter: Date | undefined;
	setSignUpAfter: (date: Date | undefined) => void;
	metricsAsAt: Date | undefined;
	setMetricsAsAt: (date: Date | undefined) => void;
	selectedCoaches: string[];
	setSelectedCoaches: (selected: string[]) => void;
}

export function CoachStats({
	coachingType,
	coachMetrics,
	coaches,
	signUpAfter,
	setSignUpAfter,
	metricsAsAt,
	setMetricsAsAt,
	selectedCoaches,
	setSelectedCoaches,
}: Props) {
	const {t} = useI18n();
	const coachMetricsSeries = useMemo(() => {
		const series =
			selectedCoaches.length === 0
				? coaches.map((coach) => coach.id)
				: selectedCoaches;
		return series.map((id) => {
			const coach = coaches.find((coach) => coach.id === id);
			return coach?.name ?? 'Loading ...';
		});
	}, [coaches, selectedCoaches]);

	const funnelStages =
		coachingType === 'domestic'
			? DOMESTIC_COACHING_FUNNEL_STAGES
			: COACHING_FUNNEL_STAGES;

	const seriesSuffix =
		coachingType === 'domestic' ? 'ByDomesticFunnelStage' : 'ByFunnelStage';

	const studentsByFunnelStage = useGroupedMetricsData(
		coachMetrics,
		funnelStages.map((stage) => ({
			label: `${t(`metrics.funnel_stages.${stage.toLowerCase()}`)}`,
			key: `students${seriesSuffix}.${snakeToCamel(stage)}`,
		})),
	);

	const archivedStudentsByFunnelStage = useGroupedMetricsData(
		coachMetrics,
		funnelStages.map((stage) => ({
			label: `${t(`metrics.funnel_stages.${stage.toLowerCase()}`)}`,
			key: `archivedStudents${seriesSuffix}.${snakeToCamel(stage)}`,
		})),
	);

	return (
		<>
			<Stack spacing="lg" justifyContent="space-between">
				<Heading type="h3" spacing="lg">
					Coaching Stats
				</Heading>
				<Stack spacing="sm" justifyContent="space-between">
					<DatePicker
						mode="single"
						textWrap="nowrap"
						selected={signUpAfter}
						placeholder="Sign Up After"
						onSelect={setSignUpAfter}
					/>
					<DatePicker
						mode="single"
						textWrap="nowrap"
						selected={metricsAsAt}
						placeholder="Metrics As At"
						onSelect={setMetricsAsAt}
					/>
					<Select
						isMulti
						wrap="nowrap"
						value={selectedCoaches.map((id) => {
							const coach = coaches.find((coach) => coach.id === id);
							return {label: coach?.name ?? 'Loading ...', value: id};
						})}
						options={coaches.map((coach) => {
							return {label: coach.name ?? '', value: coach.id};
						})}
						onChange={(selected) =>
							setSelectedCoaches(selected.map((s) => s.value))
						}
					/>
				</Stack>
			</Stack>
			<Grid columns={2}>
				<Stack direction="column" alignItems="flex-start">
					<Heading type="h4" spacing="sm">
						Students by funnel stage by coach
					</Heading>
					<StackedBarChartResponsive
						height={300}
						palette="sunset"
						data={studentsByFunnelStage}
						series={coachMetricsSeries}
					/>
				</Stack>
				<Stack direction="column" alignItems="flex-start">
					<Heading type="h4" spacing="sm">
						Archived students by funnel stage
					</Heading>
					<StackedBarChartResponsive
						height={300}
						palette="sunset"
						data={archivedStudentsByFunnelStage}
						series={coachMetricsSeries}
					/>
				</Stack>
			</Grid>
			<CoachMetricsTable
				coachingType={coachingType}
				coachMetrics={coachMetrics ?? []}
			/>
		</>
	);
}
